import moment from 'moment/moment.js';
import React, { useEffect, useState } from 'react';
import { Progress, Table } from 'react-daisyui';
import { Link, useSearchParams } from 'react-router-dom';

import * as castApi from '../../api/cast.api';
import CastsListFilter from '../../components/filters/CastsListFilter';
import { CastModel, CastStatus } from '../../dtos';

const renderItem = (item: CastModel, index: number) => {
  return (
    <tr key={index}>
      <th className="flex flex-row items-center">
        {item.account && <img className="h-8 w-8 rounded-5 mr-2" src={item.account.logo} alt="" />}
        {!item.account && <div className="h-8 w-8 rounded-5 mr-2" />}
        <div>
          <Link to={`/accounts/${item.account?.id}`}>
            <div>{item.account?.name} </div>
            <div className="badge badge-outline">{item.account?.type}</div>
          </Link>
        </div>
      </th>
      <td>
        {item.icon && <img className="h-8 w-8 rounded-5 mr-2" src={item.icon} alt="" />}
        {!item.icon && <div className="h-8 w-8 rounded-5 mr-2" />}
      </td>
      <td>
        {item.image && <img className="h-8 w-8 rounded-5 mr-2" src={item.image} alt="" />}
        {!item.image && <div className="h-8 w-8 rounded-5 mr-2" />}
      </td>
      <td>
        <div className="truncate w-40">{item.title}</div>
      </td>
      <td>
        <div className="truncate w-40">{item.body}</div>
      </td>
      <td>{item.status}</td>
      <td>
        <div className="badge badge-outline">{item.topic}</div>
        {item.status === CastStatus.COMPLETED && (
          <div className="badge badge-outline">{item.countCastedKeys} casted keys</div>
        )}
        {item.status !== CastStatus.COMPLETED && (
          <div className="badge badge-outline">{item.countKeys} keys</div>
        )}
      </td>

      <td>{moment(item.castAt).utc().format('YYYY/MM/DD HH:mm:ss')}</td>
      <td>
        <Link to={`/casts/${item.id}`} className="link-primary">
          View
        </Link>
      </td>
    </tr>
  );
};

const CastsList: React.FC = () => {
  const [data, setData] = useState<CastModel[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const fetch = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await castApi.list(Object.fromEntries(searchParams.entries()));
      setData(response.data.content);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  useEffect(() => {
    fetch().then();
  }, [fetch]);

  return (
    <div>
      <CastsListFilter />
      <div className="overflow-x-auto">
        <Table className="my-5 rounded-lg w-full" zebra>
          <thead>
            <tr>
              <th>Account</th>
              <th>Icon</th>
              <th>Image</th>
              <th>Title</th>
              <th>Body</th>
              <th>Status</th>
              <th>Topic/Platform</th>
              <th>Cast at (UTC)</th>
              <th />
            </tr>
          </thead>
          {!loading && (
            <tbody>{data && data.map((item: CastModel, index) => renderItem(item, index))}</tbody>
          )}
        </Table>
        {loading && <Progress color="primary" />}
      </div>
    </div>
  );
};

export default CastsList;
