import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment/moment.js';
import React, { useEffect, useState } from 'react';
import { Badge, Button, CodeMockup, Divider, Progress } from 'react-daisyui';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as castApi from '../../api/cast.api';
import InfoRow from '../../components/common/InfoRow';
import { CastModel, CastStatus } from '../../dtos';
import { parseUser } from '../../utils/userObject';

const CastsView: React.FC = () => {
  const { id } = useParams();

  const auth0 = useAuth0();
  const user = parseUser(auth0.user);

  const [data, setData] = useState<CastModel | null>(null);

  const navigate = useNavigate();

  const fetch = React.useCallback(async () => {
    try {
      const response = await castApi.view(id as string);
      setData(response.data.content);
    } catch (e) {
      console.error(e);
    }
  }, [id]);

  const remove = React.useCallback(async () => {
    try {
      await castApi.remove(id as string);
      toast('Removed');
      navigate('/casts/list');
    } catch (e) {
      console.error(e);
    }
  }, [id, fetch]);

  const decline = React.useCallback(
    async (reason: string) => {
      try {
        await castApi.decline(id as string, reason);
        toast('Declined');
        fetch();
      } catch (e) {
        console.error(e);
      }
    },
    [id, fetch]
  );

  const approve = React.useCallback(async () => {
    try {
      await castApi.approve(id as string);
      toast('Approved');
      fetch();
    } catch (e) {
      console.error(e);
    }
  }, [id, fetch]);

  useEffect(() => {
    fetch().then();
  }, [fetch]);

  if (!data) {
    return <Progress color="primary" />;
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="">
          <h1 className="text-lg font-extrabold">
            {data.title}
            <Link to={`/accounts/${data.account?.id}`}>
              <Badge className="ml-5">From {data.account?.name}</Badge>
            </Link>
          </h1>
          <div className="text-base-content/70 text-sm">#{data.id}</div>
        </div>

        {user?.isAdmin() && data.status === CastStatus.PENDING && (
          <div className="space-x-5">
            <Button
              size="sm"
              color="error"
              onClick={() => {
                const reason = prompt('Reason?');
                if (reason) {
                  decline(reason);
                }
              }}>
              Decline
            </Button>
            <Button
              size="sm"
              color="success"
              onClick={() => {
                if (confirm('Are you sure')) {
                  approve();
                }
              }}>
              Approve
            </Button>
          </div>
        )}
      </div>

      <Divider />
      <div className="flow flex lg:flex-row flex-col w-full">
        <div className="w-full px-4">
          <InfoRow left={'Title'} right={<div className="break-all">{data.title}</div>} />
          <InfoRow left={'Body'} right={<div className="break-all">{data.body}</div>} />

          <InfoRow
            left={'Icon'}
            right={
              data.icon ? (
                <div className="text-sm flex items-center bg-base-100 p-2">
                  <div className="break-all">{data.icon}</div>
                  <img className="block avatar w-12 h-12" src={data.icon} />
                </div>
              ) : null
            }
          />

          <InfoRow
            left={'Image'}
            right={
              data.image ? (
                <div className="text-sm flex items-center bg-base-100 p-2">
                  <div className="break-all">{data.image}</div>
                  <img className="block avatar w-12 h-12" src={data.image} />
                </div>
              ) : null
            }
          />

          <InfoRow left={'Action URL'} right={<div className="break-all">{data.actionUrl}</div>} />

          <InfoRow left={'Topic'} right={data.topic} />

          <InfoRow left={'Platform'} right={data.platform} />

          <InfoRow left={'Target all'} right={data.targetAllKeys ? 'Yes' : 'No'} />

          <InfoRow
            left={'Target'}
            right={(() => {
              if (data.targetAllKeys) {
                return `~ ${data.countKeys} key/s at time of creation`;
              } else {
                return data.targetKeys[0];
              }
            })()}
          />

          {user?.isAdmin() && data.status === CastStatus.PENDING && (
            <Button
              size="sm"
              color="error"
              onClick={() => {
                if (confirm('Are you sure')) {
                  remove();
                }
              }}>
              Delete
            </Button>
          )}
        </div>
        <Divider />
        <div className="lg:w-1/3 w-full px-4 text-sm">
          <InfoRow left={'Status'} right={data.status} />

          <InfoRow
            left={'Cast at'}
            right={moment(data.castAt).utc().format('YYYY/MM/DD HH:mm:ss')}
          />

          <InfoRow
            left={'Created'}
            right={moment(data.createdAt).utc().format('YYYY/MM/DD HH:mm:ss')}
          />

          <Divider />

          {data.review && <h2 className="font-bold text-lg">Review by</h2>}
          {data.review?.reason && <InfoRow left={'Decline reason'} right={data.review.reason} />}
          {data.review?.user && (
            <InfoRow
              left={'Reviewer'}
              right={
                <>
                  {data.review.user.name}{' '}
                  <small className="text-xs">{data.review.user.email}</small>
                </>
              }
            />
          )}

          <Divider />
          <InfoRow left={'Run id'} right={data.runId} />

          {data.status === CastStatus.COMPLETED && (
            <InfoRow left={'Sent to'} right={`${data.countCastedKeys} key/s`} />
          )}

          <CodeMockup className="mt-2">
            <div className="p-5 whitespace-pre-wrap">{JSON.stringify(data.run, null, 4)}</div>
          </CodeMockup>
        </div>
      </div>
    </>
  );
};

export default CastsView;
