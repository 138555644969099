import moment from 'moment/moment.js';
import React, { useEffect, useState } from 'react';
import { Button, Progress, Table } from 'react-daisyui';
import { Link, useSearchParams } from 'react-router-dom';

import * as accountApi from '../../api/account.api';
import AccountsListFilter from '../../components/filters/AccountsListFilter';
import AccountCreateModal from '../../components/modals/AccountCreateModal';
import { AccountModel, AccountType } from '../../dtos';

function renderItem(item: AccountModel, index: number) {
  return (
    <tr key={index}>
      <th className="flex flex-row items-center">
        {item.logo && <img className="h-8 w-8 rounded-5 mr-2" src={item.logo} alt="" />}
        {!item.logo && <div className="h-8 w-8 rounded-5 mr-2" />}
        <div>
          {item.name}
          <div className="block badge badge-sm badge-outline opacity-50">{item.category}</div>
          {item.type === AccountType.PROVIDER && (
            <div className="block badge badge-sm badge-outline opacity-50">{item.optType}</div>
          )}
        </div>
      </th>
      <td>
        <div className="badge badge-outline">{item.type}</div>
      </td>
      <td>
        {item.domains &&
          item.domains.map((domainObject: any, domainIndex: number) => (
            <div key={domainIndex}>{domainObject.domain}</div>
          ))}
      </td>
      <td>{item._count && item._count.keys}</td>

      <td>
        <div className="badge badge-outline">{item.status}</div>
      </td>
      <td>{moment(item.createdAt).utc().format('YYYY/MM/DD HH:mm:ss')}</td>
      <td>
        <Link to={`/accounts/${item.id}`} className="link-primary">
          View
        </Link>
      </td>
    </tr>
  );
}

const AccountsList: React.FC = () => {
  const [data, setData] = useState<AccountModel[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [createModal, setCreateModal] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const fetch = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await accountApi.list(Object.fromEntries(searchParams.entries()));
      setData(response.data.content);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  useEffect(() => {
    fetch().then();
  }, [fetch]);

  return (
    <div>
      <div>
        <AccountsListFilter />
      </div>
      <div className="flex justify-end">
        <Button onClick={() => setCreateModal(true)}>Create</Button>
      </div>
      <div className="overflow-x-auto">
        <Table className="my-5 rounded-lg w-full" zebra>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Domains</th>
              <th>Provider/Consumer Keys</th>
              <th>Status</th>
              <th>Created at (UTC)</th>
              <th />
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {data && data.map((item: AccountModel, index) => renderItem(item, index))}
            </tbody>
          )}
        </Table>
        {loading && <Progress color="primary" />}
      </div>

      {createModal && (
        <AccountCreateModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            fetch();
          }}
        />
      )}
    </div>
  );
};

export default AccountsList;
