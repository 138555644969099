import { withAuth0 } from '@auth0/auth0-react';
import { Component } from 'react';

class Login extends Component<any, any> {
  render() {
    const { loginWithRedirect } = this.props.auth0;
    return (
      <div className="container mx-auto py-5 prose text-center">
        <button className="btn btn-primary" onClick={loginWithRedirect}>
          Login via Auth0
        </button>
      </div>
    );
  }
}

// @ts-ignore
export default withAuth0(Login);
