import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { Button, Modal } from 'react-daisyui';
import { toast } from 'react-toastify';

import * as accountApi from '../../api/account.api';
import {
  AccountCategory,
  AccountFlag,
  AccountModel,
  AccountOptType,
  AccountStatus,
  AccountType,
  AccountWebhookOnly
} from '../../dtos';

interface CreateModalProps {
  onClose: Function;
  onSubmit: Function;
  account: AccountModel;
}

const AccountEditModal: React.FC<CreateModalProps> = (props) => {
  const [name, setName] = useState(props.account.name);
  const [logo, setLogo] = useState(props.account.logo);
  const [category, setCategory] = useState(props.account.category);
  const [status, setStatus] = useState(props.account.status);
  const [optType, setOptType] = useState(props.account.optType);
  const [flags, setFlags] = useState(props.account.flags);
  const [webhookOnly, setWebhookOnly] = useState(
    props.account.webhookOnly ? AccountWebhookOnly.TRUE : AccountWebhookOnly.FALSE
  );

  const submit = React.useCallback(async () => {
    try {
      await accountApi.edit(
        props.account.id,
        name,
        logo,
        category,
        status,
        optType,
        webhookOnly,
        flags
      );
      toast('Edited account');
      props.onClose();
      props.onSubmit();
    } catch (error) {
      console.log('error', error);
    }
  }, [name, logo, status, optType, props, category, webhookOnly, flags]);

  const updateFlags = (e: any) => {
    const options = e.target.options;
    const value = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected && options[i].value) {
        value.push(options[i].value);
      }
    }

    setFlags(value as AccountFlag[]);
  };

  return (
    <Modal open onClickBackdrop={() => props.onClose()}>
      <h3 className="text-lg font-bold">Edit account</h3>

      <div className="text-center">
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Name</span>
          </label>
          <input
            type="text"
            placeholder="Solrise Finance"
            className="input input-bordered rounded-lg w-full max-w-xs"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Logo</span>
          </label>
          <input
            type="url"
            placeholder="https://example.com/image.jpg"
            className="input input-bordered rounded-lg w-full max-w-xs"
            value={logo}
            onChange={(e) => setLogo(e.target.value)}
            required
          />
        </div>
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Status</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={status}
            onChange={(e) => setStatus(e.target.value as AccountStatus)}>
            {(Object.keys(AccountStatus) as (keyof typeof AccountStatus)[]).map((key) => (
              <option value={AccountStatus[key]} key={AccountStatus[key]}>
                {capitalize(AccountStatus[key])}
              </option>
            ))}
          </select>
        </div>
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Opt type</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={optType}
            onChange={(e) => setOptType(e.target.value as AccountOptType)}>
            {(Object.keys(AccountOptType) as (keyof typeof AccountOptType)[]).map((key) => (
              <option value={AccountOptType[key]} key={AccountOptType[key]}>
                {capitalize(AccountOptType[key])}
              </option>
            ))}
          </select>
        </div>
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Category</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={category}
            onChange={(e) => setCategory(e.target.value as AccountCategory)}>
            {(Object.keys(AccountCategory) as (keyof typeof AccountCategory)[]).map((key) => (
              <option value={AccountCategory[key]} key={AccountCategory[key]}>
                {capitalize(AccountCategory[key])}
              </option>
            ))}
          </select>
        </div>

        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Flags</span>
          </label>
          <select
            multiple
            className="select select-bordered w-full max-w-xs"
            value={flags}
            onChange={(e) => updateFlags(e)}>
            <option value="">Clear selection</option>
            <option value={AccountFlag.CAST_TRUSTED}>Cast Trusted</option>
          </select>
        </div>

        {props.account.type === AccountType.CONSUMER && (
          <div className="form-control my-5">
            <label className="label">
              <span className="label-text">Webhook Only</span>
            </label>
            <select
              className="select select-bordered w-full max-w-xs"
              value={webhookOnly}
              onChange={(e) => setWebhookOnly(e.target.value as AccountWebhookOnly)}>
              <option value={AccountWebhookOnly.FALSE}>False</option>
              <option value={AccountWebhookOnly.TRUE}>True</option>
            </select>
          </div>
        )}
      </div>

      <Modal.Actions>
        <Button onClick={() => submit()} color="primary">
          Edit
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AccountEditModal;
