export enum OrderDir {
  ASC = 'asc',
  DESC = 'desc'
}

export enum AccountFlag {
  CAST_TRUSTED = 'cast-trusted'
}

export enum AccountExternalSource {
  DIALECT = 'dialect'
}

export enum UserRole {
  ADMIN = 'admin'
}

export enum WebhookType {
  CASTS = 'casts',
  EVENTS = 'events'
}

export enum AccountType {
  PROVIDER = 'provider',
  CONSUMER = 'consumer'
}

export enum AccountOptType {
  OPT_IN = 'opt-in',
  OPT_PRESELECT = 'opt-preselect',
  OPT_DISABLED = 'opt-disabled'
}

export enum AccountCategory {
  DAPP = 'dapp',
  NFT = 'nft'
}

export enum AccountWebhookOnly {
  FALSE = '0',
  TRUE = '1'
}

export enum AccountStatus {
  NEW = 'new',
  ACTIVE = 'active',
  DISABLED = 'disabled',
  REMOVED = 'removed'
}

export interface Domain {
  id: string;
  accountId: string;
  domain: string;
  createdAt: string;
  updatedAt: string;
}

export interface AccountExternal {
  id: string;
  accountId: string;
  externalSource: AccountExternalSource;
  externalIds: string[];
}

export interface Webhook {
  id: string;
  accountId: string;
  url: string;
  secret: string;
  type: string;
}

export interface AccountCounts {
  keys: number | undefined;
  keysProviderConnected: number | undefined;
  keysProvider: number | undefined;
  keysConsumerConnected: number | undefined;
  keysConsumer: number | undefined;
  broadcast: number | undefined;
  unicast: number | undefined;
}

export interface AccountModel {
  id: string;
  logo: string;
  name: string;
  category: string;
  webhookOnly: boolean;
  flags: AccountFlag[];
  apiKey: string | undefined;
  optType: AccountOptType;
  status: AccountStatus;
  type: AccountType;
  domains: Domain[] | undefined;
  webhooks: Webhook[] | undefined;
  accountExternals: AccountExternal[] | undefined;
  createdAt: string;
  updatedAt: string;
  _count: AccountCounts | undefined;
}

export enum CastType {
  BROADCAST = 'broadcast',
  UNICAST = 'unicast'
}

export enum CastStatus {
  PENDING = 'pending',
  RUNNING = 'running',
  APPROVED = 'approved',
  COMPLETED = 'completed',
  DECLINED = 'declined'
}

export enum CastPlatform {
  ALL = 'all',
  WEB = 'web',
  MOBILE = 'mobile',
  IOS = 'ios',
  ANDROID = 'android'
}

export interface CastModel {
  id: string;
  icon: string;
  image: string;
  title: string;
  body: string;
  status: string;
  type: string;
  topic: string;
  actionUrl: string;
  platform: CastPlatform;
  targetAllKeys: boolean;
  castAt: Date | null;
  targetKeys: string[];
  createdAt: Date;
  account: AccountModel | undefined;
  countCastedKeys: number;
  countKeys: number | undefined;
  runId: string | undefined;
  run: string | undefined;
  review: {
    reason: string | undefined;
    user: null | {
      name: string | undefined;
      email: string | undefined;
    };
  };
}

export interface Key {
  id: string;
  accountId: string;
  key: string;
  accountType: AccountType | undefined;
  account: AccountModel | undefined;
  createdAt: Date;
  connected: boolean;
  muted: boolean;
  readAt: Date;
}
