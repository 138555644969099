import { axiosInstance } from './axios';

export async function list(query: any) {
  const queryString = query ? `?${new URLSearchParams(query)}` : '';
  return await axiosInstance.get(`keys${queryString}`);
}

export async function removeKey(id: string) {
  return await axiosInstance.delete(`keys/${id}`);
}
