import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AuthProvider from './components/AuthProvider';
import MainLayout from './components/layout/MainLayout';
import './index.css';

// eslint-disable-next-line import/order
import 'react-toastify/dist/ReactToastify.css';

import DashboardView from './pages/DashboardView';
import AccountsList from './pages/accounts/AccountsList';
import AccountsView from './pages/accounts/AccountsView';
import Login from './pages/auth/Login';
import CastsCompose from './pages/casts/CastsCompose';
import CastsList from './pages/casts/CastsList';
import CastsView from './pages/casts/CastsView';
import KeysList from './pages/keys/KeysList';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// @ts-ignore
// @ts-ignore
root.render(
  <React.StrictMode>
    {process.env.REACT_APP_ENV !== 'production' && (
      <div
        style={{
          width: '100%',
          color: 'white',
          textAlign: 'center',
          backgroundColor: '#1467ff',
          fontWeight: 'bold',
          marginBottom: 10
        }}>
        dev
      </div>
    )}
    <ToastContainer theme={'dark'} />

    <BrowserRouter>
      <AuthProvider
        LoggedIn={
          <MainLayout>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<DashboardView />} />
              <Route path="/accounts">
                <Route path="list" element={<AccountsList />} />
                <Route path=":id" element={<AccountsView id={null} />} />
              </Route>
              <Route path="/casts">
                <Route path="list" element={<CastsList />} />
                <Route path="compose" element={<CastsCompose />} />
                <Route path=":id" element={<CastsView />} />
              </Route>
              <Route path="/keys">
                <Route path="list" element={<KeysList />} />
              </Route>
            </Routes>
          </MainLayout>
        }
        LoggedOut={
          <MainLayout>
            <ToastContainer />
            <Routes>
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
              <Route path="/auth">
                {/*
  // @ts-ignore */}
                <Route path="login" element={<Login />} />
              </Route>
            </Routes>
          </MainLayout>
        }
      />
    </BrowserRouter>
  </React.StrictMode>
);
