import {
  AccountFlag,
  AccountOptType,
  AccountStatus,
  AccountType,
  AccountWebhookOnly,
  WebhookType
} from '../dtos';

import { axiosInstance } from './axios';

export async function list(query: any) {
  const queryString = query ? `?${new URLSearchParams(query)}` : '';
  return await axiosInstance.get(`accounts${queryString}`);
}

export async function listSimple() {
  return await axiosInstance.get(`accounts/list-simple`);
}

export async function view(id: string) {
  return await axiosInstance.get(`accounts/${id}`);
}

export async function create(name: string, logo: string, type: AccountType) {
  return await axiosInstance.post('accounts', {
    name,
    logo,
    type
  });
}

export async function edit(
  id: string,
  name: string,
  logo: string,
  category: string,
  status: AccountStatus,
  optType: AccountOptType,
  webhookOnly: AccountWebhookOnly,
  flags: AccountFlag[]
) {
  return await axiosInstance.post(`accounts/${id}`, {
    name,
    logo,
    status,
    category,
    optType,
    flags,
    webhookOnly: webhookOnly !== '0'
  });
}

export async function domainCreate(accountId: string, domain: string) {
  return await axiosInstance.post(`accounts/${accountId}/domain`, {
    domain
  });
}

export async function domainRemove(accountId: string, domainId: string) {
  return await axiosInstance.delete(`accounts/${accountId}/domain?domainId=${domainId}`);
}

export async function externalIdCreate(accountId: string, source: string, dappId: string) {
  return await axiosInstance.post(`accounts/${accountId}/externalId`, {
    source,
    dappId
  });
}

export async function externalIdRemove(accountId: string, source: string, dappId: string) {
  return await axiosInstance.delete(
    `accounts/${accountId}/externalId?source=${source}&dappId=${dappId}`
  );
}

export async function webhookCreate(accountId: string, url: string, type: WebhookType) {
  return await axiosInstance.post(`accounts/${accountId}/webhook`, {
    url,
    type
  });
}

export async function webhookRemove(accountId: string, webhookId: string) {
  return await axiosInstance.delete(`accounts/${accountId}/webhook?webhookId=${webhookId}`);
}
