import { useAuth0 } from '@auth0/auth0-react';
import { capitalize } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-daisyui';
import { useSearchParams } from 'react-router-dom';

import * as accountApi from '../../api/account.api';
import { AccountModel, AccountStatus, AccountType } from '../../dtos';
import { parseUser } from '../../utils/userObject';

const KeysListFilter: React.FC = () => {
  const [show, setShow] = useState(false);
  const [accountType, setAccountType] = useState<AccountType | undefined>(undefined);
  const [accountId, setAccountId] = useState<string | undefined>(undefined);
  const [key, setKey] = useState<string | undefined>(undefined);
  const [accounts, setAccounts] = useState<AccountModel[]>([]);

  const [searchParams, setSearch] = useSearchParams();

  const auth0 = useAuth0();
  const user = parseUser(auth0.user);

  useEffect(() => {
    accountApi.listSimple().then((response) => {
      setAccounts(response.data.content);
    });
  }, []);

  useEffect(() => {
    setAccountType((searchParams.get('type') as AccountType) ?? undefined);
    setAccountId(searchParams.get('accountId') ?? undefined);
    setKey(searchParams.get('key') ?? undefined);
  }, [searchParams]);

  const search = useCallback(() => {
    setSearch({
      accountType: accountType ?? '',
      accountId: accountId ?? '',
      key: key ?? ''
    });
  }, [accountType, accountId, key]);

  if (!show) {
    return (
      <>
        <Button onClick={() => setShow(!show)}>Filter</Button>
      </>
    );
  }

  return (
    <>
      <div>
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Type</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={accountType ?? ''}
            onChange={(e) => setAccountType(e.target.value as AccountType)}>
            <option value="">All</option>
            {(Object.keys(AccountType) as (keyof typeof AccountType)[]).map((key) => (
              <option value={AccountType[key]} key={key}>
                {capitalize(AccountType[key])}
              </option>
            ))}
          </select>
        </div>

        {user?.isAdmin() && (
          <div className="form-control my-5">
            <label className="label">
              <span className="label-text">Account</span>
            </label>
            <select
              className="select select-bordered w-full max-w-xs"
              value={accountId ?? ''}
              onChange={(e) => setAccountId(e.target.value as AccountStatus)}>
              <option value="">Any</option>
              {accounts.length &&
                accounts.map((account) => (
                  <option value={account.id} key={account.id}>
                    {capitalize(account.name)}
                  </option>
                ))}
            </select>
          </div>
        )}

        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Key</span>
          </label>
          <input
            type="text"
            placeholder=""
            className="input input-bordered rounded-lg w-full max-w-xs"
            value={key ?? ''}
            onChange={(e) => setKey(e.target.value)}
            required
          />
        </div>
      </div>

      <Button className="mr-10" color="success" onClick={() => search()}>
        Search
      </Button>
      <Button onClick={() => setShow(!show)}>Hide Filter</Button>
    </>
  );
};

export default KeysListFilter;
