import { omit } from 'lodash';

import { UserRole } from '../dtos';

interface UserObject {
  name: string;
  email: string;
  picture: string;
  sub: string;
  type: string | undefined;
  accountId: string | undefined;
}

export class AuthedUser {
  readonly user: UserObject;

  constructor(user: UserObject) {
    this.user = user;
  }

  isAdmin() {
    return this.user.type === UserRole.ADMIN;
  }

  isUser() {
    return !this.user.type;
  }

  getAccountId() {
    return this.user.accountId;
  }

  getName() {
    return this.user.name;
  }

  getEmail() {
    return this.user.email;
  }
}

export function parseUser(user: any) {
  if (!user) {
    return null;
  }

  return new AuthedUser({
    ...omit(user, ['https://cast/type', 'https://cast/accountId']),
    type: user['https://cast/type'],
    accountId: user['https://cast/accountId']
  } as UserObject);
}
